import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { Link } from 'gatsby'

const Career = () => (
  <Layout>
    <Seo title="Kariyer" />
    <div className="container">
      <div className="about-hero">
        <StaticImage
          src="../images/logo.svg"
          alt="BM Creative Works"
          className="menu-logo"
        />
      </div>
    </div>
    <div className="container">
      <div className="career-head">
        <h1>Açık Pozisyonlar</h1>
      </div>
      <div className="career-text">
        Ekibimize uygun olduğunuzu düşünüyorsanız sizinle tanışmak isteriz!
        <br />
        <br />
        Aşağıda uygun bir pozisyon bulamazsanız deneyiminizi, portföyünüzü ve
        neden bizimle çalışmak istediğinizi{' '}
        <a href="mailto:business@bmcreative.works">
          business@bmcreative.works
        </a>{' '}
        adresine e-posta gönderebilirsiniz.
      </div>
      <div className="positions">
        <div>
          <a href="mailto:business@bmcreative.works?subject=Fullstack Developer">
            <h2>Fullstack Developer</h2>
          </a>
          <h3>Ankara — Freelancer</h3>
          <p>
            Her zaman bir üst seviyeye elini uzatan yazılımcı sen misin? Eğer
            cevabın evetse biz de tam senin gibi birini arıyoruz! BM’in
            üstlendiği projelerde etkin bir rol oynayabileceğinden eminsen
            React, React Native ve Node dillerine hakimsen ve Ankara’da ikamet
            ediyorsan aradığımız çalışma arkadaşı sen olabilirsin. Tanışmamız
            lazım, neden kendini anlatan özgeçmişini, portfolyonu veya GitHub
            bağlantını yollamıyorsun?
          </p>
        </div>
        <div>
          <a href="mailto:business@bmcreative.works?subject=Graphic Designer">
            <h2>Graphic Designer</h2>
          </a>
          <h3>Ankara — Tam Zamanlı</h3>
          <p>
            Çok iyi üreten, aklından geçeni ekrana tam manasıyla aktarabilen bir
            grafik tasarımcısın ve Ankara'da ikamet ediyorsun öyle mi? Tam
            zamanlı olarak BM'in ihtiyaç duyduğu dijital ve baskı tasarımlarını
            yapabilirim, Adobe ailesini çok iyi şekilde kullanabiliyorum ve
            ekibin bir parçası olmaya hazırım diyorsan özgeçmişinle birlikte
            portfolyonu yollamalısın.
          </p>
        </div>
      </div>
    </div>
    <div className="container freelancer">
      <div className="career-head">
        <Link to="/freelance/">
          <h2>Freelance?</h2>
        </Link>
      </div>
    </div>
  </Layout>
)

export default Career
